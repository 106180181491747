import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <ul className="icons">
                    <li><a href="#" className="icon alt fab fa-facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="#" className="icon alt fab fa-instagram"><span className="label">Instagram</span></a></li>
                    <li><a href="#" className="icon alt fas fa-envelope"><span className="label">Email</span></a></li>
                </ul>
                <ul className="copyright">
                  <li>An NYU ITP Citizen Science Project</li>
                  <li><a href="https://www.adrianbautista.com/itp-blog/tags/citizen-science">Adrian Bautista</a></li>
                  <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                </ul>
            </section>
        )
    }
}

export default Footer
